import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Uebergabe() {
  return (
    <LayoutXT>
      <h1 className="left">Vermögensübergabe zu Lebzeiten</h1>
      <div className="rem-of-13 gray-a lheight18">
        Zu unserem Leistungsspektrum zählt insbesondere auch eine gute,
        durchdachte und steueroptimierte Beratung hinsichtlich der
        Vermögensübergabe zu Lebzeiten, welche oftmals neben einer
        Nachlassplanung mittels Testamenten oder Erbvertrag sinnvoll oder gar
        wirtschaftlich notwendig ist, insbesondere wenn eine Immobilie oder
        Unternehmensanteile z.B. an die zukünftige Generation übertragen werden
        sollen. <br />
        <br />
        Wir beraten Sie fachkundig, so beispielsweise wie die übergebende
        Generation über Nießbrauchsrechte, Wohnrechte oder über weitere
        Gestaltungsmittel zur Altersversorgung einerseits die Sicherheit hat,
        dass sie das Vermögen transparent und geordnet bereits zu Lebzeiten auf
        die zukünftige Generation übertragen hat, aber andererseits dennoch über
        das selbst erwirtschaftete Vermögen noch abgesichert ist, obgleich es
        bereits bei den Nachfolgern angekommen ist.
        <br />
        <br />
        Unsere umfassende Beratung erfolgt unter Berücksichtigung von
        zukünftigen pflichtteilsberechtigen Abkömmlingen sowie der Erbschaft-
        und Schenkungsteuer und hat stets eine maßgeschneiderte Lösung nach
        Ihren persönlichen Bedürfnissen und Ihren Vermögensverhältnissen zum
        Ziel.
      </div>
    </LayoutXT>
  )
}
